import React from 'react';
import logo from '../../assets/logo.svg';
import arrow from '../../assets/arrow.svg';
import { Link } from "react-router-dom";

const NavBar = ({page=""}) => {
    return(
        <nav className="nav">
            <div className="nav-wrapper">
                <div className="nav-title">
                    <img className="svg-logo" src={logo} alt="Kargo Logo"/> 
                    <Link className="title" to="/">Cerberus Control Dashboard</Link>
                </div>
                {page && page==="home" && (
                    <Link className="link" to="/region-management">      
                        <img className="svg-arrow" src={arrow} alt="right arrow"/> 
                        <span>REGION MANAGEMENT PAGE</span>
                    </Link> 
                )}
                {page && page==="region-management" && (
                    <Link className="link" to="/">      
                        <img className="svg-arrow" src={arrow} alt="right arrow"/> 
                        <span>BACK TO HOME PAGE</span>
                    </Link> 
                )}
            </div>
        </nav>
    ) 

}

export default NavBar
