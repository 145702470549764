import React from 'react';

const ConfigsInputForm = ({
        inputData = {}, 
        handleChange, 
        handleDeactivate, 
        addConfig,
        regions,
        samePixel,
        handleSamePixel,
        handlePixelChangeForAll
    }) => {
        
    if(!regions || regions.length === 0){
        return null;
    }

    const regionWeightCells = regions.map((region)=>{
        const regionID = region.id;
        const config = inputData[regionID];

        if(!config){
            return (
                <div 
                key={`regionInput${regionID}`} 
                className="flex-1 form-flex-column"
                >
                    <div className="input-title">
                        {region.shortname.toUpperCase()}
                    </div>  
                    <div className="input-item">
                    <button 
                    className="pure-button outer-blue"
                    type="button"
                    onClick={()=>addConfig(regionID)}
                    >
                        ADD
                    </button>
                    </div>
                </div>
            )}
        else{
        return(
            <div 
                key={`regionInput${regionID}`} 
                className="flex-1 form-flex-column"
            >
                <div className="input-title">
                    {region.shortname.toUpperCase()}
                </div>            
                <div className="input-item">
                    <label htmlFor={`sync_weight${regionID}`}>Sync Weight</label>
                    <input
                    type="number" 
                    step="0.01" 
                    min="0" 
                    max="1" 
                    required
                    id={`sync_weight${regionID}`}
                    value={config.sync_weight}
                    onChange={(e)=>handleChange(e,regionID)}
                    />        
                    <button 
                        className="pure-button outer-red"
                        type="button"
                        onClick={()=>handleDeactivate(regionID)}
                        >
                            DISABLE
                    </button>
                </div>       
            </div>
        )}})

    const regionPixelCells = ()=>{
        if(!inputData || Object.keys(inputData).length===0 ){
          return null;  
        }else if(samePixel){   
            // if samePixel = true, meaning all region use same pixel
            // use the pixel value first existing config
            const pixelToEdit =  Object.values(inputData)[0].sync_pixel || "";
     
                return(
                    <div 
                        key="regionInput-all" 
                        className="form-flex-column"
                        style={{flex: regions.length*2}}
                    >            
                        <label htmlFor={`sync_pixel`}>Sync Pixel </label>
                        {pixelToEdit.length>5000 && <span className="pixel-too-long-warning">Sync Pixel is longer than 5000, please double check before save.</span>}
                        <textarea
                        className=""  
                        id={`sync_pixel`}
                        value={ pixelToEdit || ""}
                        onChange={ (e)=>{handlePixelChangeForAll(e)}}
                        ></textarea> 
                        <br/>
                        <button 
                            className="pure-button outer-blue button-long" 
                            type="button"
                            onClick = {()=>{handleSamePixel(false)}}
                        >
                            SET INDIVIDUAL SYNC PIXELS 
                        </button> 
                    </div>
                )
        }else{
            return(
                regions.map((region)=>{
                    const regionID = region.id;
                    const config = inputData[regionID];
            
                    if(!config){ 
                        return(<div  key={`regionInput${regionID}`} className="flex-1 form-flex-column"></div>)
                     }
                    else{
                        return(
                            <div 
                                key={`regionInput${regionID}`} 
                                className="flex-1 form-flex-column"
                            >
                                <label htmlFor={`sync_pixel${regionID}`}>Sync Pixel </label>

                                {config.sync_pixel.length>5000 && <span className="pixel-too-long-warning">Sync Pixel is longer than 5000, please double check before save.</span>}
                
                                <textarea
                                id={`sync_pixel${regionID}`}
                                value={config.sync_pixel || ""}
                                onChange={(e)=>handleChange(e, regionID)}
                                ></textarea>    
                                <br/>
                                
                                <button 
                                    className="pure-button outer-blue long" 
                                    type="button"
                                    onClick = {()=>{handleSamePixel(true, config.sync_pixel)}}
                                >
                                    Use this pixel for all regions
                                </button> 
                            </div>
                        )}
                })
            )
        }
    }

    return (
        <>  
            <br/>
            <legend>Region Configurations</legend>
            <div className="form-row">
            { regionWeightCells }  
            </div>  
            <div className="form-row">
            { regionPixelCells() }       
            </div>
        </>
    )
}

export default ConfigsInputForm
