import React from 'react'
import NavBar from "../components/NavBar/NavBar";
import MessagePopUp from '../components/MessagePopUp/MessagePopUp';
import RegionManagement from "../components/RegionManagement/RegionManagement";

const RegionPage = () => {
    return (
        <>  
            <MessagePopUp/>
            <NavBar page="region-management"/>
            <RegionManagement/>
        </>
    )
}

export default RegionPage
