import React, { useContext} from 'react';
import { RegionContext } from '../../contexts/region-context';
import { MessageContext } from '../../contexts/message-context'; 

const RegionColumns = ({rowType, configs=null, partnerName=""}) => {

    const regions = useContext(RegionContext).state;
    const updateMessage = useContext(MessageContext).setState;

    if(!regions || regions.length === 0){
        return null;
    }

    const copyToClipboard = ()=>{
        navigator.clipboard.writeText(`https://crb.kargo.com/api/v1/dsync/${partnerName}?gdpr=<GDPR_APPLIES>&gdpr_consent=<GDPR_CONSENT_STRING>&us_privacy=<US_PRIVACY_STRING>&exid=<USER_ID>`).then(
            ()=>{
                updateMessage("Success: Copied to clipboard")
            }
        ).catch(()=>{
            updateMessage("Error to copy.")
        })
    }

    const weightCells = regions.map((region)=>{
        const config = configs && configs.find((config)=> config.region_id === region.id)
        
        if(!config){
            return (<div key={region.id} headers={`region${region.id}`} className="flex-2 cell-weight"></div>)
        }

        return(<div 
            key={region.id} 
            id={`weight${config.id}`}
            headers={`region${region.id}`}
            className="flex-2 cell-weight">
                {Number(config.sync_weight).toFixed(2)}
            </div>)
    })

    const pixelCells = regions.map((region)=>{
        const config = configs && configs.find((config)=> config.region_id === region.id)
        
        if(!config){
            return (<div key={region.id} headers={`region${region.id}`} className ="flex-2"></div>)
        }

        return(
            <div key={region.id} headers={`region${region.id}`} className="flex-2 cell-pixel">
                <textarea className="textarea" value={config.sync_pixel} disabled> </textarea>
                <div>
                    <button 
                        className="pure-button outer-blue button-long"
                        onClick={copyToClipboard}
                    >
                        COPY SYNC PIXEL
                    </button>
                </div>

            </div>
            )
        })

    const pixelSameCell = configs && configs.length>0 ?
            (<div className="cell-pixel" style={{flex: regions.length*2}}>
                <textarea className="textarea" value={configs[0].sync_pixel || ""} disabled> </textarea>
                <div>
                    <button 
                        className="pure-button outer-blue button-long"
                        onClick={copyToClipboard}
                    >
                        COPY SYNC PIXEL
                    </button>
                </div>
            </div>)
            : (<div className =""></div>)

    switch(rowType){
        case "head1":
            return regions.map((region, index)=>{
                if (index === Math.floor(regions.length/2)){
                    return(<div key={region.id} className="flex-2">SYNC WEIGHT</div>)
                }else{
                    return(<div key={region.id} className="flex-2"></div>)
                }        
        });
        case "head2":
            return regions.map((region)=><div id={`region${region.id}`} key={region.id} className="flex-2">{region.shortname.toUpperCase()}</div>);
        case "region-weight":
            return weightCells;
        case "region-pixel":
            return pixelCells;
        case "region-pixel-same":
            return pixelSameCell;
        default: 
            return null
    }
}

export default RegionColumns