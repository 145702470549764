import React from 'react';

import SearchBar from './SearchBar';
import PartnerTable from './PartnerTable'

const PartnerList = ()=>{

     return(
            <div className="wrapper">
                <SearchBar />
                <PartnerTable />
            </div> )
}

export default PartnerList;