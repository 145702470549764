import React, { createContext, useState, useEffect, useContext } from 'react'
import { PartnerContext } from './partner-context';

export const ShowPartnerContext = createContext([]);

export const ShowPartnerProvider = ({children}) =>{
    const [state, setState] = useState([])
    const partners = useContext(PartnerContext).state;

    useEffect(() => {
        setState(partners);
    }, [partners])

    return( 
            <ShowPartnerContext.Provider value={{state, setState}}>
                {children}
            </ShowPartnerContext.Provider>
            )
}