import React, { createContext, useState, useEffect, useContext } from 'react';
import {MessageContext} from './message-context';

export const PartnerContext = createContext([]);

export const PartnerProvider = ({children})=>{

    const [state, setState] = useState([])
    const setMessage = useContext(MessageContext).setState

    useEffect(()=>{
        let mounted = true;
        
        fetch('/api/v1/partners')
        .then(response => response.json())
        .then(data => {
            if(mounted){
                setState(data)
                setMessage("Success: Loading partners data");
        }})
        .catch(err=>{
            console.log(err.message)
            if(mounted){
                setMessage("Error: Loading partners data");}
        });
        
        return function cleanup() {
            mounted = false
        }

    }, [setMessage])

    return(
        <PartnerContext.Provider value={{state, setState}}>
            {children}
        </PartnerContext.Provider>
    )
}


