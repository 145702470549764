import React from 'react';
import NavBar from '../components/NavBar/NavBar';
import SyncTestTools from '../components/SyncTestTools/SyncTestTools';
import MessagePopUp from '../components/MessagePopUp/MessagePopUp';
import { PartnerProvider } from '../contexts/partner-context';
import { ShowPartnerProvider } from '../contexts/showPartner-context';
import { SyncLogProvider } from '../contexts/syncLog-context';

import PartnerList from '../components/PartnerList/PartnerList';

const Home = () => {
    return ( 
        <SyncLogProvider>
            <PartnerProvider>
                    <ShowPartnerProvider>
                        <div>
                            <MessagePopUp/>
                            <NavBar page="home"/>
                            <SyncTestTools/>
                            <PartnerList/>
                        </div>
                    </ShowPartnerProvider>
            </PartnerProvider>
        </SyncLogProvider>
    )
}

export default Home
