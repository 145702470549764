import React, {createContext, useState} from 'react'

export const MessageContext = createContext("");

export const MessageProvider = ({children})=>{

    const [state, setState] = useState("")

    return(
        <MessageContext.Provider value={{state, setState}}>
            {children}
        </MessageContext.Provider>
    )
}

