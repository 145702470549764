import React, {createContext, useState, useEffect, useContext} from 'react';
import {MessageContext} from './message-context';

export const RegionContext = createContext([]);

export const RegionProvider = ({children})=>{
    
    const [state, setState] = useState([])
    const setMessage = useContext(MessageContext).setState

    useEffect(()=>{
        let mounted = true;
      
            fetch('/api/v1/regions')
            .then(response => response.json())
            .then(data => {         
                if(mounted){
                    setState(data)
                    setMessage("Success: Loading Regions Data")
                }})
            .catch(err=>{          
                console.log(err.message);
                if(mounted){
                    setMessage("Error: Loading Regions Data");}
                });

        return function cleanup() {
            mounted = false
        }
        
    }, [setMessage])

    return(
        <RegionContext.Provider value={{state, setState}}>
            {children}
        </RegionContext.Provider>
    )
}