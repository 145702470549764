import React, { useState, useContext } from 'react';
import { PartnerContext } from '../../../contexts/partner-context';
import { MessageContext } from '../../../contexts/message-context';
import { RegionContext } from '../../../contexts/region-context';

import PartnerInputForm from './PartnerInputForm';
import ConfigsInputForm from './ConfigsInputForm';

const NewEditForm = ({editID, setEdit, setHightlight}) => {

    const partners = useContext(PartnerContext).state
    const regions = useContext(RegionContext).state;
    const updatePartner = useContext(PartnerContext).setState;
    const updateMessage = useContext(MessageContext).setState;
    const [error, setError] = useState({sspids: "", name: "", save: ""})

    const emptyConfig = {
            id: 0, 
            partner_id: editID, 
            region_id: 0,
            sync_weight: 0, 
            sync_pixel: ""
        }

    const newConfigs = regions&& regions.length>0 ? regions.map((region)=>({...emptyConfig, region_id: region.id})): {};

    const newPartner = {
        partner : {
            id: 0, 
            type: "DSP",
            name: "",
            ssp_ids: "",
            is_same_sync_pixel: true
        }, 
        region_configs: newConfigs
    }
   
    // editID=0: New Partner Form, editID>0: Edit Partner Form
    const dataToEdit = partners && partners.length>0 && editID>0 ? partners.find((data)=>data.partner.id===editID) : newPartner;

    const initialPartner = dataToEdit.partner;

    let initalConfigs = {}

    dataToEdit.region_configs && dataToEdit.region_configs.length>0 
    && dataToEdit.region_configs.forEach((config)=>{
        initalConfigs[config.region_id] = {...config}
    })

    const [partnerInput, setpartnerInput] = useState(initialPartner);
    const [configsInput, setConfigsInput] = useState(initalConfigs);

    const handleConfigsInputChange = (e, regionID)=>{
    
            let editConfig = configsInput[regionID];
 
            if(e.target.id.includes('sync_weight')){
                
                editConfig = e.target.value.length>0 ? 
                    { ...editConfig, sync_weight: parseFloat(e.target.value)} 
                    : { ...editConfig, sync_weight: ""};  
            
            } else if(e.target.id.includes('sync_pixel')){    
                editConfig = { ...editConfig, sync_pixel: e.target.value}  
            } 
            setConfigsInput({...configsInput, [regionID]: editConfig});      
    }

    const handlePartnerInputChange = (e)=>{
        resetError()
        setpartnerInput({...partnerInput, [e.target.id]:e.target.value})
        }

    const handlePixelChangeForAll = (e)=>{
        let confitsCopy = {}
        for (const [key, val] of Object.entries(configsInput)) {
            confitsCopy[key] = {...val, sync_pixel: e.target.value}
        }
        setConfigsInput(confitsCopy);
    }

    const handleSamePixel = (bool=true, pixel = "") =>{
        // update the is_same_sync_pixel in partner input state
        setpartnerInput({...partnerInput, is_same_sync_pixel:bool})

        // update sync_pixel in configs input state
        // if set true, and has argment 2, update sync_pixel in config configsInput state for all existing region configs
        if(bool){
            let confitsCopy = {}
            for (const [key, val] of Object.entries(configsInput)) {
                confitsCopy[key] = {...val, sync_pixel: pixel}
            }
            setConfigsInput(confitsCopy);
        }
    }

    const addRegionConfig = (regionID)=>{
        if(partnerInput.is_same_sync_pixel){
            const newPixel = Object.values(configsInput).length>0 ? Object.values(configsInput)[0].sync_pixel : "";
            const newConfig = {...emptyConfig, region_id:regionID, sync_pixel: newPixel } 
            setConfigsInput({...configsInput, [regionID]:newConfig})      
        } else{
            const newConfig = {...emptyConfig, region_id:regionID} 
            setConfigsInput({...configsInput, [regionID]:newConfig})      
        }
    }

    const DeactivateRegionConfig = (regionID)=>{
        let newConfigs = {...configsInput};
        newConfigs[regionID] = {...newConfigs[regionID], sync_weight: 0}
        setConfigsInput(newConfigs);
    }

    const resetInput = () =>{
        setpartnerInput(initialPartner);
        setConfigsInput(initalConfigs);
    } 
    
    const resetInputAndClose = () =>{
        resetInput();
        setEdit(null)
    }

    const handleCancel = () =>{
        setHightlight(editID)
        resetInputAndClose()
    }

    const resetError = () => {
        setError({name: "", save: "", sspids: ""});
    }

    const handleSubmit = (e)=>{

        e.preventDefault();
        resetError()

        // step1: trim the input and validation 
        const partnerDataToSubmit = {
            ...partnerInput,
            name: partnerInput.name.trim(),
            ssp_ids: partnerInput.ssp_ids.trim() || "",
        }

        setpartnerInput(partnerDataToSubmit);

        if(!partnerDataToSubmit.name || partnerDataToSubmit.name.length===0){
            setError({...error, name: "Partner name cannot be empty"})
            return null;
        }

        if( partnerDataToSubmit.name.length>32){
            setError({...error, name: "Partner name cannot be longer than 32"})
            return null;
        }

        if(!partnerDataToSubmit.name.match(/^[_a-zA-Z0-9]+$/)){
            setError({...error, name: "Partner name should have alphanumeric characters only"})
            return null;
        }

        if( partnerDataToSubmit.ssp_ids.length>64){
            setError({...error, sspids: "SSP IDs cannot be longer than 64"})
            return null;
        }
        
        let trimmedConfigsData = {}

        let configsDataToSubmit = Object.values(configsInput).length>0 ?
                Object.values(configsInput).map((config)=>{
                    const pixelToSubmit = config.sync_pixel.trim()|| "";
                    const trimmedConfig = {...config, sync_pixel: pixelToSubmit };
                    trimmedConfigsData[config.region_id] = trimmedConfig; 
                    return (trimmedConfig);
                })
                : [];

        setConfigsInput(trimmedConfigsData);
        
        // step2: covert the data format 
        const dataToSubmit = configsDataToSubmit.length>0? 
                {
                    partner:partnerDataToSubmit,
                    region_configs: configsDataToSubmit
                }
                :  {
                    partner:partnerDataToSubmit
                }

        // step3: for PUT request, check if dataToSubmit is same as current data
         if(editID>0 && JSON.stringify(dataToEdit)===JSON.stringify(dataToSubmit)){
            return null;
         }


        // step4: submit and call api 
        const fetchURL = editID>0  ? `/api/v1/partners/${editID}`: "/api/v1/partners"
        const fetchMethod = editID>0 ? "PUT" : "POST";
        const SuccessMessage = editID>0 ? "Update a partner" : "Add a new parter" 

        fetch(fetchURL, {
            method: fetchMethod, 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSubmit),
            })
            .then(response => {
                console.log(response.status)
                return response.json()
            })
            .then(data => {
                if(data.error){
                    console.log(data.error)
                    setError({...error, save: `Error: ${data.error.message}`})
                }else{
                updatePartner(data)
                updateMessage(`Success: ${SuccessMessage}`)
                const idTOHightlight = editID>0 ? editID : data[data.length-1].partner.id
                setHightlight(idTOHightlight);
                resetInputAndClose(idTOHightlight);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setError({...error, save: `Error: ${error}`})
            }
        );
      
     }

    if(editID===null || editID < 0 ){
        return null;
    }else{
        return (       
            <form 
                className="pure-form partner-form" 
                onSubmit = {handleSubmit}
            >
                <div className="form-head">
                    {editID>0 ? <h3>Edit Partner ID #{editID}</h3> : <h3>Create A New Partner</h3> }
                    <div className="form-buttons">
                            <button 
                                    className="pure-button outer-blue selected"
                                    type="submit"
                            >
                                SAVE
                            </button>
                            <button 
                                    className="pure-button outer-blue"
                                    type="button"
                                    onClick= {resetInput}
                                >
                                RESET
                            </button>
                            <button 
                                    className="pure-button outer-grey"
                                    type="button"
                                    onClick= {handleCancel}
                            >
                                CANCEL
                            </button>
                            <span style = {{color: "red"}}>{error.save}</span>
                    </div>
                </div>
            <fieldset>
                <PartnerInputForm 
                    inputData= {partnerInput} 
                    handleChange={handlePartnerInputChange}
                    nameError = {error.name}
                    sspidsError = {error.sspids}
                    />
                <ConfigsInputForm 
                    inputData={configsInput} 
                    handleChange={handleConfigsInputChange}
                    handleDeactivate ={DeactivateRegionConfig}
                    addConfig = {addRegionConfig}
                    regions={regions}
                    samePixel = {partnerInput.is_same_sync_pixel}
                    handleSamePixel={handleSamePixel}
                    handlePixelChangeForAll={handlePixelChangeForAll}
                />
                </fieldset>         
            </form>        
    )}
}

export default NewEditForm
