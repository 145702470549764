import React, { useState, useContext } from 'react';
import { ShowPartnerContext } from '../../contexts/showPartner-context';

import PartnerRow from './PartnerRow';
import RegionColumns from './RegionColumns';
import NewEditForm from './forms/NewEditForm';

const PartnerTable = ()=>{

    const showPartners = useContext(ShowPartnerContext).state;
    const updateShowPartners = useContext(ShowPartnerContext).setState;

    const [editID, setEditID] = useState(null);
    const [hightlightID, setHightlight] = useState(null);

    const handleSort = (sortTerm)=>{
        const sortresult = sortList(sortTerm);
        updateShowPartners(sortresult);
    }

    const sortList = (sortTerm)=>{
        switch(sortTerm){
            case "partner_id_asc":
                return showPartners.slice().sort((a, b)=>{
                    const [idA, idB] = [a.partner.id, b.partner.id]
                    if(idA < idB){
                        return -1;
                    }else{
                        return 1;
                    }
                });
            case "partner_id_desc":
                return showPartners.slice().sort((a, b)=>{
                    const [idA, idB] = [a.partner.id, b.partner.id]
                    if(idA > idB){
                        return -1;
                    }else{
                        return 1;
                    }
                });
            case "partner_name_asc":
                return showPartners.slice().sort((a, b)=>{
                    const [nameA, nameB] = [a.partner.name.toUpperCase(), b.partner.name.toUpperCase()]
                    if(nameA < nameB){
                        return -1;
                    }else{
                        return 1;
                    }
                });
            case "partner_name_desc":
                return showPartners.slice().sort((a, b)=>{
                    const [nameA, nameB] = [a.partner.name.toUpperCase(), b.partner.name.toUpperCase()]
                    if(nameA < nameB){
                        return 1;
                    }else{
                        return -1;
                    }
                });
            default: 
                return showPartners;
        }
    }
 
    const partnerRows = showPartners.map( (data) => {
            if(data.partner.id !== editID){
                return(
                    <PartnerRow 
                    key={data.partner.id} 
                    data={data}
                    setEdit={setEditID} 
                    hightlightID={hightlightID}
                    setHightlight={setHightlight}
                    />
                );
            } else {
                return(
                    <NewEditForm  key={data.partner.id}  editID={editID} setEdit={setEditID} setHightlight={setHightlight}/>
                )
            }
            
    });                   

    return(
      <>
       { editID !== 0 && <button 
            className="pure-button button-dark"
            onClick = {()=>{setEditID(0)}}
        >
            ADD PARTNER
        </button>}
        
        { editID === 0 && <NewEditForm editID={editID} setEdit={setEditID} setHightlight={setHightlight} /> }
        
        <div className="table">
            <div className="row header head1">
                <div id="id" className="flex-1"> 
                </div>
                <div id="ssp_ids" className="flex-2">  </div>
                <div id="name" className="flex-2"> 
                   
              
                </div>
                <div id="type" className="flex-1">  </div>
                {<RegionColumns rowType="head1" />}
                <div className="flex-2"> </div>

            </div>
            <div className="row header">
                <div id="sortid" className="flex-1"> 
                ID 
                    <span className="sort-button-wrapper">
                        <button id="partner_id_desc" className="btn no-dec" onClick={()=>handleSort("partner_id_desc")}>
                            <div className="arrow-up small"></div>
                        </button>
                        <button id="partner_id_asc" className="btn no-dec" onClick={()=>handleSort("partner_id_asc")}>
                            <div className="arrow-down small"></div>
                        </button> 
                    </span>
                </div>
                <div className="flex-2"> SSP IDs </div>
                <div id="sortid" className="flex-2"> 
                    NAME 
                    <span className="sort-button-wrapper">
                        <button id="partner_name_desc" className="btn no-dec" onClick={()=>handleSort("partner_name_desc")}>
                            <div className="arrow-up small"></div>
                        </button>
                        <button id="partner_name_asc" className="btn no-dec" onClick={()=>handleSort("partner_name_asc")}>
                            <div className="arrow-down small"></div>
                        </button>   
                    </span>
                </div>
                <div className="flex-1"> TYPE </div>
                {<RegionColumns rowType="head2" />}
                <div id="detail" className="flex-2 header-buttons">ACTIONS {"&"} DETAILS</div>
            </div>
        </div>
        <div className="table">  
            <div>
               {showPartners.length>0 && partnerRows }            
            </div>
        </div>      
      </>                 
)}

export default PartnerTable;
