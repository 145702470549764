import React, {useContext, useState} from 'react'
import { RegionContext } from '../../contexts/region-context'
import RegionInputRow from './RegionInputRow'

const RegionManagement = () => {
   
    const regions = useContext(RegionContext).state;
    
    const [isShowNewRegionInput, SetNewRegionInput] = useState(false);

    const newRegion = {
        id: 0,
        shortname: "",
        name: ""
    }


    const regionEditRows = regions && regions.map((region)=><RegionInputRow key={region.id} region={region}/>)
              
    return (
        <div className="wrapper">
            <h1 className="section-title">Manage Region Configurations</h1>
            
            <div className="table">
                <div className="row header region">
                    <div id="id" className="flex-1"> ID </div>
                    <div id="shortname" className="flex-2"> Short Name </div>
                    <div id="name" className="flex-3"> Name </div>
                    <div id="button" className="flex-3"> </div>
                </div>  
                {regions && regions.length>0 && regionEditRows }  
                {isShowNewRegionInput && <RegionInputRow key={0} region={newRegion} SetNewRegionInput= {SetNewRegionInput}/> }  
                <button 
                    className="pure-button button-dark"
                    onClick={()=>{SetNewRegionInput(true)}}
                >   
                    ADD REGION
                </button>
                       
            </div>
        </div>
    )
}

export default RegionManagement
