// TODO: test getDspIds fetch function and its option setting in staging and production site
import React, { useState, useEffect, useContext } from 'react';
import { SyncLogContext } from '../../contexts/syncLog-context';
import expandLogo from '../../assets/expand.svg';

const SyncTestTools = () => {
    let client_id = null;
    let dsp_ids = null;

    const cerberus_host = window.location.hostname === "cerberus-control.kargo.com" ? "crb.kargo.com" : window.location.hostname.replace(/-control/, "")

    const log = useContext(SyncLogContext).state;

    const setLog = useContext(SyncLogContext).setState;

    const updateLog = (message) => {
        setLog((state) => [...state, `${Date.now()}: ${message}`]);
    }

    const [showDetail, setShowDetail] = useState(false);
    const [iframeSrc, setIframeSrc] = useState("");





    const guid = () => {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    const createCookie = (name, value, days) => {
        let expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    const readCookie = (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    // function eraseCookie(name) {
    //     createCookie(name, "", -1);
    // }

    const initSync = () => {
        client_id = readCookie('client_id')
        updateLog('initiating sync through //' + cerberus_host + '/api/v1/initsync/' + client_id);
        setIframeSrc(`//${cerberus_host}/api/v1/initsync/${client_id}`);
    }

    const resetClientId = () => {
        client_id = guid();
        updateLog("generated new client_id: " + client_id);
        writeClientId(client_id);
    }

    const readClientId = () => {
        client_id = readCookie('client_id')
        if (!client_id) {
            updateLog("no existing client_id");
        }
        else {
            updateLog("client_id: " + client_id);
        }
    }

    const writeClientId = (id = null) => {
        createCookie('client_id', id, 2000)
        readClientId()
    }

    const resetDspIds = () => {
        dsp_ids = null
        updateLog("cleared dsp_ids");
        writeDspIds(dsp_ids)
    }

    const readDspIds = () => {
        if (readCookie("KSYNC_DSPIDS")) {
            dsp_ids = JSON.parse(readCookie("KSYNC_DSPIDS"))
        }
        if (!dsp_ids) {
            updateLog("no dsp_ids");
        }
        else {
            updateLog("found dsp_ids: " + JSON.stringify(dsp_ids))
        }
    }

    const getDspIds = () => {
        client_id = readCookie('client_id')
        const url = `//${cerberus_host}/api/v1/ids/${client_id}`

        fetch(url, { credentials: 'include' })
            .then((response) => response.text())
            .then((data) => {
                const json = JSON.parse(atob(data));
                dsp_ids = json;
                updateLog("got dsp ids: " + JSON.stringify(json));
            }).catch((error) => {
                if (error.message) {
                    updateLog("getDSPIds: " + error.message)
                } else {
                    updateLog("getDSPIds: error, cannot get dsp ids");
                }
            });
    }

    const writeDspIds = (id = null) => {
        createCookie('KSYNC_DSPIDS', JSON.stringify(dsp_ids), 2000)
        updateLog("stored dfp_ids: " + JSON.stringify(dsp_ids))
    }

    const init = () => {
        readClientId()
        if (!client_id) {
            resetClientId()
        }
        readDspIds()
    }

    useEffect(() => {
        let mounted = true;
        updateLog("init");
        if (mounted) {
            init();
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    const showLogList = log.map((logItem, i) => <li key={i}>{logItem}</li>)

    return (
        <>
            {showDetail ? (
                <div className="sync-area-wrapper">
                    <div className="sync-area">
                        <div className="sync-buttons">
                            <button className="pure-button" onClick={initSync}>Initiate Sync</button>
                            <button className="pure-button" onClick={resetClientId}>Reset Client Id</button>
                            <button className="pure-button" onClick={readClientId}>Read Client Id</button>
                            <button className="pure-button" onClick={resetDspIds}>Reset DSP IDs</button>
                            <button className="pure-button" onClick={getDspIds}>Get DSP IDs</button>
                        </div>
                        <div className="log-area">
                            <span className="log-title">SYNC TEST LOGS</span>
                            <ul className="log-list">
                                {log.length > 0 && showLogList}
                            </ul>
                        </div>
                        <button className="sync-close-btn" onClick={() => setShowDetail(false)}>
                            <span className="sync-close-X">+</span>
                        </button>
                    </div>
                </div>
            )
                : (
                    <button className="sync-expand-btn" onClick={() => setShowDetail(true)}>
                        <span >TEST TOOLS</span>
                        <img className="sync-expand-svg" src={expandLogo} alt="expand" />
                    </button>
                )}

            {iframeSrc.length > 0
                && <iframe
                    title="initSync"
                    style={{ display: "none" }}
                    src={iframeSrc}
                >
                </iframe>
            }
        </>
    )
}

export default SyncTestTools
