import React, { useState, useEffect, useContext } from 'react'
import { PartnerContext } from '../../contexts/partner-context';
import { ShowPartnerContext } from '../../contexts/showPartner-context';
import searchLogo from '../../assets/searchLogo.svg';

const SearchBar = ()=>{
    const partners = useContext(PartnerContext).state;
    const updateShowPartners = useContext(ShowPartnerContext).setState;
    
    const [inputTerm, setInputTerm] = useState("")

    useEffect(() => {
        handleSearchReset()
    // eslint-disable-next-line 
    }, [partners])

    const handleInputChange = (e) =>{
        const term = e.target.value.trim().toLowerCase();   
 
        const searchResult = partners.filter((data)=>{
            const {partner, region_configs} = data;
            if(region_configs && region_configs.length>0){
                return(
                    partner.name.toLowerCase().includes(term)
                    || partner.ssp_ids.toLowerCase().includes(term)
                    || partner.id.toString().includes(term)
                    || region_configs.find((config)=> config.sync_pixel.toLowerCase().includes(term))      
                )    
            }else{
                return(
                    partner.name.toLowerCase().includes(term)
                    || partner.ssp_ids.toLowerCase().includes(term)
                    || partner.id.toString().includes(term)      
                    )    
        }});
        updateShowPartners(searchResult);
        setInputTerm(e.target.value);
    }

    const handleSearchReset = () =>{
        updateShowPartners(partners);
        setInputTerm("")
    }


    return (
    <div className="input-wrapper">
        <img className="input-logo" src={searchLogo} alt="serach logo"></img>
        <input 
                aria-label="search"
                className="input-input" 
                placeholder="search"
                type="search"
                maxLength="5000"
                value={inputTerm}
                onChange={handleInputChange}
            />
             {inputTerm.trim().length >0 ? 
                <button onClick={handleSearchReset} className="search-reset">+</button> : null}

    </div>)
}


export default SearchBar
