import React, { useContext, useEffect, useState } from 'react'
import { MessageContext } from '../../contexts/message-context'

const MessagePopUp = () => {

    const message = useContext(MessageContext).state;
    const updateMessage = useContext(MessageContext).setState;
    const [showMessage, setShowMessage] = useState(false)

    useEffect((()=>{
        if(message && message.length>0){
            setShowMessage(true)
            setTimeout(()=>{
               updateMessage("")
               setShowMessage(false)
               }, 5000);
        }else{
            return null
        }
    }), [message, updateMessage])
    
    const subClass = message && message.includes("Error")? 
                        "error": message && message.includes("Success")? 
                                "success": null;

    return (
        <>
            {showMessage && <div className={`message-popup ${subClass}`}><span>{message}</span></div>}
        </>
    )
}

export default MessagePopUp
