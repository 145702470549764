import React, {useState, useContext} from 'react';
import { RegionContext } from '../../contexts/region-context';
import { MessageContext } from '../../contexts/message-context';


const RegionInputRow= ({region, SetNewRegionInput}) => {
    const updateRegion = useContext(RegionContext).setState;
    const updateMessage = useContext(MessageContext).setState;
    const [inputData, setInputData] = useState(region);
    const [error, setError] = useState({shortname: "", name: "", save: ""});

    const resetError = () => {
        setError({shortname: "", name: ""});
    }

    const handleInputChange = (e)=>{
         setInputData({...inputData, [e.target.id]: e.target.value});
         resetError()
    }   

    const handleSubmit = (e)=>{
        e.preventDefault();

        resetError()
            
        // step1: trim the input and validation 
        const dataToSubmit = {
            ...inputData,
            shortname: inputData.shortname.trim(),
            name: inputData.name.trim()
        }

        setInputData(dataToSubmit);

        if( !dataToSubmit.shortname || dataToSubmit.shortname.length===0){
            setError({...error, shortname: "Shortname cannot be empty"})
            return null;
        }

        if( dataToSubmit.shortname.length>32){
            setError({...error, shortname: "Shortname cannot be longer than 32"})
            return null;
        }

        if( !dataToSubmit.shortname.match(/^[a-zA-Z0-9]+$/)){
            setError({...error, shortname: "Shortname should have alphanumeric characters only"})
            return null;
        }
        
        if( !dataToSubmit.name || dataToSubmit.name.length===0){
            setError({...error, name: "Name cannot be empty"})
            return null;
        }

        if( dataToSubmit.name.length>100){
            setError({...error, name: "Name cannot be longer than 100"})
            return null;
        }

        // step2: for put requst, if the dataToSubmit are same as region, return and do nothing
        if(inputData.id>0 && JSON.stringify(dataToSubmit)===JSON.stringify(region)){
            return null;
        }

        // step3: submit and call api 
        const fetchURL = inputData.id>0  ? `/api/v1/regions/${inputData.id}`: "/api/v1/regions"
        const fetchMethod = inputData.id>0 ? "PUT" : "POST";
        const SuccessMessage = inputData.id>0 ? "Updated a region configuration" : "Added a new region" 

        fetch(fetchURL, {
            method: fetchMethod, 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({region: dataToSubmit}),
            })
            .then(response => {
                console.log(response.status)
                return response.json()
            })
            .then(data => {
                if(data.error){
                    console.log(data.error)
                    setError({...error, save: `Error: ${data.error.message}`})
                }else{
                updateRegion(data)
                updateMessage(`Success: ${SuccessMessage}`)
                }
                if(inputData.id===0){
                    SetNewRegionInput(false);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setError({...error, save: `Error: ${error}`})
            }
        );

    }


    return (
        <>
        <form className="pure-form" onSubmit={handleSubmit}>
            <div className="row partner region" >
                    <div headers="id" className="flex-1">{region.id===0? "new": region.id}</div>
                    <div headers="shortname" className="flex-2">
                        <input
                            aria-label="shortname"
                            type="text" 
                            id="shortname"
                            name="shortname"
                            max="32"
                            min="0"
                            required
                            value={inputData.shortname || ""}
                            onChange={(e)=>{handleInputChange(e)}}
                        />
                    </div>
                    <div headers="name" className="flex-3">  
                        <input
                            aria-label="name"
                            type="text" 
                            id="name"
                            name="name"
                            max="100"
                            min="0"
                            value={inputData.name || ""}
                            required
                            onChange={(e)=>{handleInputChange(e)}}
                        />
                    </div>
                    <div headers="button" className="flex-3">  
                        <button 
                        className="pure-button outer-blue"
                        type="submit"
                        >SAVE
                        </button>
                    </div>
            </div>
        </form>
            <div className="row error">
                <div headers="id" className="flex-1"></div>
                <div headers="shortname" className="flex-2">{error.shortname}</div>
                <div headers="name" className="flex-3">{error.name}</div>
                <div headers="button" className="flex-3">{error.save}</div>
            </div>
        </>
    )
}

export default RegionInputRow
