import React from 'react'

const NotFound = () => {
    return (
        <div className="wrapper">
            <h1 style={{"textAlign":"center"}}>
                Page Not Found.
            </h1>
        </div>
       
    )
}

export default NotFound
