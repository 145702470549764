import React, {useState, useContext} from 'react';
import RegionColumns from './RegionColumns';
import { MessageContext } from '../../contexts/message-context';
import { SyncLogContext } from '../../contexts/syncLog-context';

const PartnerRow = ({data={}, setEdit, hightlightID, setHightlight})=>{

    const [iframeSrc, setIframeSrc] = useState("");
    const updateMessage = useContext(MessageContext).setState;

    const setLog = useContext(SyncLogContext).setState;
    const updateLog = (message) => {
        setLog((state)=>[...state, `${Date.now()}: ${message}`]);
    }

    if (!data || data.length===0 || !data.partner){
        return null;
    }
    const {partner, region_configs} = data;
    
    const {id, ssp_ids, name, type, is_same_sync_pixel} = partner;

    const cerberus_host = window.location.hostname === "cerberus-control.kargo.com" ? "crb.kargo.com" : window.location.hostname.replace(/-control/, "")

    function readCookie(cookieName) {
        var nameEQ = cookieName + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    const syncPartner = () => {
        const client_id = readCookie('client_id')
        updateMessage('initiating for partner ' + name + 'sync through //' + cerberus_host + '/api/v1/initsync/' + client_id);
        updateLog('initiating for partner ' + name + 'sync through //' + cerberus_host + '/api/v1/initsync/' + client_id);
        setIframeSrc(`//${cerberus_host}/api/v1/initsync/${client_id}?partners=${name}`);
    }

    return(
        <>
        <div  className="row partner">
                <div headers="id" className="flex-1">{id}</div>
                <div headers="sspids" className="flex-2">{ssp_ids}</div>
                <div headers="name" className="flex-2 cell-bold">{name}</div>
                <div headers="type" className="flex-1">{type}</div>
                {<RegionColumns rowType="region-weight" configs={region_configs}/>}
                <div headers="detail" className="flex-2 cell-center">
                    {hightlightID ===id ? 
                    (<button id="btn-close" className="btn no-dec" onClick={()=>setHightlight(null)}>
                        <div className="arrow-up"></div>
                    </button>)
                    : (<button id="btn-detail" className="btn no-dec" onClick={()=>setHightlight(id)}>
                        <div className="arrow-down"></div>
                    </button>   
                    )}
                </div>
        </div>
        {hightlightID ===id ? (           
            <div className="row detail">
                <div className="flex-1"></div>
                <div className="flex-2"></div>
                <div className="flex-2"></div>
                <div className="flex-1 cell-pixel pixel-title">Sync Pixel</div>
                {is_same_sync_pixel && <RegionColumns rowType="region-pixel-same" configs={region_configs} partnerName={name}/>}
                {!is_same_sync_pixel && <RegionColumns rowType="region-pixel" configs={region_configs} partnerName={name}/>}
                <div headers="action" className="flex-2 cell-center cell-pixel">
                    <button 
                        className="pure-button outer-grey"
                        onClick={syncPartner}
                        >
                        SYNC
                    </button>
                    <br/>
                    <button 
                        className="pure-button outer-blue"
                        onClick={()=>{
                            setEdit(id)
                            setHightlight(null);
                            }} 
                    >
                        EDIT
                    </button>
                </div>              
            </div>
          
        ): null
        }
        { iframeSrc.length>0 
                && <iframe 
                        title="initSync" 
                        style={{display: "none"}} 
                        src={iframeSrc}
                    >
                    </iframe>
        }
        </>
    )
}

export default PartnerRow;