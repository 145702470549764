import React from 'react';
import {  BrowserRouter, HashRouter, Switch, Route} from "react-router-dom";
import { MessageProvider } from './contexts/message-context';
import { RegionProvider } from './contexts/region-context';

import Home from './pages/Home';
import RegionPage from './pages/RegionPage';
import NotFound from './pages/NotFound404'

function App() {
  return (
      <MessageProvider>
        <RegionProvider>
          <BrowserRouter>
            <Switch>
              <Route exact path="/">
                <HashRouter>
                <Switch>
                  <Route exact path="/region-management" component={RegionPage} />
                  <Route exact path="/" component={Home} />
                  <Route component={NotFound} />
                  </Switch>
                </HashRouter>
              </Route>
              <Route component={NotFound} />
            </Switch>
          </BrowserRouter>
        </RegionProvider>
      </MessageProvider>
  );
}

export default App;
