import React from 'react'

const PartnerInputForm = ({inputData = [], handleChange, nameError="", sspidsError=""}) => {
    return (
        <>
            <legend>Partner Information</legend>
            <div className="form-row">
                <div className="flex-1 form-flex-column">
                    <label htmlFor="ssp_ids">SSP IDs</label>
                    <input 
                            type="text" 
                            id="ssp_ids"
                            name="ssp_ids"
                            max="64"
                            min="0"
                            value={inputData.ssp_ids || ""}
                            onChange={handleChange}
                    />
                    <div className="input-error" style={{color:"red"}}>{sspidsError}</div>
                </div>
                <div className="flex-1 form-flex-column">
                    <label htmlFor="name">Name</label>
                    <input 
                        type="text" 
                        id="name"
                        name="name"
                        max="32"
                        min="0"
                        required
                        value={inputData.name || ""}
                        onChange={handleChange}    
                    />
                    <div className="input-error" style={{color:"red"}}>{nameError}</div>
                </div>
                <div className="flex-1 form-flex-column">
                <label htmlFor="type">Type</label>
                    <select 
                            name="type"
                            id="type"
                            value={inputData.type || "DSP"}
                            onChange={handleChange}    
                            required
                    >
                            <option value="DSP">DSP</option>
                            <option value="SSP">SSP</option>
                            <option value="GENERIC">GENERIC</option>
                    </select>
                </div>
            </div> 
        </>
    )
}

export default PartnerInputForm
