import React, {createContext, useState} from 'react'

export const SyncLogContext = createContext([]);

export const SyncLogProvider = ({children})=>{

    const [state, setState] = useState([])

    return(
        <SyncLogContext.Provider value={{state, setState}}>
            {children}
        </SyncLogContext.Provider>
    )
}